@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root{
  --primary: #6948ff;
  --secondary: #553bcb;
  --darkgrey: #22262a;
  --lightgrey: #d3d3d3;
  --grey: #808080;
}



body {
  color: #333;
  font-family: 'Montserrat', sans-serif;
  }

  ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
    columns: #fff;
  }
  
  .primary-color {
    color: var(--primary);
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  width: 100%;
  padding: 8px 0;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: #fff;
  cursor: pointer;
}

button:hover {
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
}

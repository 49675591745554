.pricing{
    width: 100%;
    margin-top: 10rem;
    padding: 0 2rem;
    height: 90vh;
}

.heading{
    display: flex;
    gap: 27rem;
    align-items: center;
}

.heading h1{
    font-size: 3.5rem;
}

.heading button{
    width: 15rem;
    height: 3rem;
}

.pricing p{
    margin-top: 2rem;
    color: var(--grey);
    font-size: 1.2rem;
}

.divider1{
    border: 1px solid var(--lightgrey);
    margin-top: 5rem;
}

@media screen and (max-width: 960px) {
    .pricing{
        align-items: center;
        height: 35vh;
    }

   .heading{
    display: grid;
    gap: 1rem;
   }

   .heading h1{
    font-size: 1.2rem;
   }

   .heading button{
    width: 24rem;
    height: 2rem;
    margin-top: 1rem;
   }

   .pricing p{
    font-size: 1rem;
   }

   .divider1{
    margin-top: 3rem;
   }
}
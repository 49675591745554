header {
    width: 100%;
    height: 100px;
    background-color: var(--darkgrey);
}

.navbar{
    justify-content: space-between;
    display: flex;
    max-width: 100%;
    padding: 1rem 1rem;
    height: 100%;
    background-color: var(--darkgrey);
    margin: auto;
    overflow: hidden;
}

.logo img{
    width: 80px;
    height: auto;;
    align-items: center;
    display: flex;
}

.nav-menu{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.nav-item{
    list-style-type: none;
}

.nav-item a{
    text-decoration: none;
    color: white;
    font-weight: 600;
}

.hamburger{
    display: none;
    position: absolute;
    top: 2rem;
    right: 1rem;
}

@media screen and (max-width: 960px) {
    .hamburger{
        display: block;
    }

    .nav-menu{
        position: fixed;
        flex-direction: column;
        left: -100%;
        top: 0;
        justify-self: center;
        background-color: var(--darkgrey);
        text-align: center;
        width: 100%;
        height: 100%;
        transition: 0.4s;
    }

    .nav-menu.active{
        left: 0;
    }

    .nav-item{
        margin: 1.5rem 0;
        font-size: 1.8rem;
    }
}
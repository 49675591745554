.section-faq{
    width: 100%;
    margin: 3rem 0;
}

.container{
    max-width: 1240px;
    padding: 1rem;
    margin: auto;
    overflow: hidden;
    display: flex;
    gap: 5rem;
}

.col-1 h3{
    font-size: 2rem;
    margin-bottom: 1.8rem;
}

.col-1>:nth-child(2){
    margin-bottom: 1rem;
}

.col-1 span:hover{
    cursor: pointer;
}

.col-2 h5{
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.col-2 p{
    margin-bottom: 2rem;
    font-size: 0.8rem;
}

@media screen and (max-width: 960px) {
    .section-faq{
        max-width: 100%;
    }

    .container{
        flex-direction: column;
    }
}
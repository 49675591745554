.testimonial{
    width: 100%;
    color: #fff;
    margin: 3rem 0;
}

.container{
    max-width: 1240px;
    padding: 1rem;
    overflow: hidden;
    margin: auto;
    
}

.outline{
    background-image: linear-gradient(to left, rgba(73, 50, 173, 0.5),rgba(73, 50, 173, 0.9)),
    url('https://images.unsplash.com/photo-1562071707-7249ab429b2a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80');
    width: 100%;
    height: 450px;
    background-position: left;
    border-radius: 10px;
}

.content{
    padding: 3rem;
    max-width: 50%;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.body{
    padding: 1.2rem 0;
}

.name p{
    font-size: 14px;
}

@media screen and (max-width: 960px) {
    .testimonial{
        margin: 1rem 0;
    }

    .content{
        max-width: 100%;
    }
}
.pricingbelow{
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-top: 5rem;
}

.first{
    flex-direction: column;
    width: 30%;
}

.first>:nth-child(1){
    text-transform: uppercase;
    color: var(--primary);
    font-weight: bold;
    font-size: 1.2rem;
}

.first>:nth-child(2){
    margin-top: 2rem;
    font-weight: bold;
    font-size: 3rem;
}

.first>:nth-child(3){
    font-size: 0.9rem;
    color: var(--grey);
}

.second p{
    font-size: 1.2rem;
}

.divider2{
    border: 1px solid var(--lightgrey);
    margin-top: 2rem;
}

@media screen and (max-width: 960px) {
    .pricingbelow{
    display: none;
    }
}
.footer{
    background-color: var(--darkgrey);
    width: 100%;
    padding: 4rem 4rem;
    height: 52vh;
}

.footer1{
    color: white;
    justify-content: space-between;
    display: flex;
}

.row1 ul a{
    color: var(--lightgrey);
}

.row1{
    display: flex;
    flex-direction: column;
}

.row1>:nth-child(1){
    font-weight: bold;
    margin-bottom: 1rem;
}

.row2>:nth-child(1){
    font-weight: bold;
    margin-bottom: 1rem;
}

.row3>:nth-child(1){
    font-weight: bold;
    margin-bottom: 1rem;
}

.row4>:nth-child(1){
    font-weight: bold;
    margin-bottom: 1rem;
}

.row5>:nth-child(1){
    font-weight: bold;
}

.r{
    color: var(--lightgrey);
    padding: 1rem 0;
}

.row2 a{
    color: var(--lightgrey);
}

.row3 a{
    color: var(--lightgrey);
}

.row4 a{
    color: var(--lightgrey);
}

.row2{
    flex-direction: column;
    display: flex;
}
.row3{
    flex-direction: column;
    display: flex;
}

.row4{
    flex-direction: column;
    display: flex;
}

.row5{
    flex-direction: column;
    display: flex;
    gap: 3rem;
}

.row1, .row2, .row3, .row4:hover{
    cursor: pointer;
}

.row5>:nth-child(2){
    font-size: 0.9rem;
    color: var(--lightgrey);
}

.email{
    display: flex;
    gap: 1rem;
}

.email input{
    width: 20rem;
    padding: 0 0.5rem;
    border: none;
    border-radius: 10px;
}

.email button{
    width: 8rem;
}

.divider{
    display: flex;
    border: 1px solid var(--grey);
    margin-top: 3rem;
}

.bottom{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    align-items: center;
    color: white;
    font-size: 0.8rem;
}

.icons:hover{
    cursor: pointer;
}

@media screen and (max-width: 960px) {
    .footer{
        flex-direction: column;
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: 30rem;
    }

    .footer1{
        flex-direction: column;
        width: 100%;
    }

    .bottom{
        width: 100%;
        font-size: 0.5rem;
    }
}


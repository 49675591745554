.contact{
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0 20.3rem;
    margin-top: 15rem;
    height: 70vh;
}

.left{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 2rem;
}

.heading h1{
    margin-bottom: 2rem;
    color: black;
    font-weight: bold;
    font-size: 2.5rem;
}

.left>:nth-child(6){
    margin-top: 2rem;
}

.right{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.9rem;
}

.right input{
    height: 3rem;
    border: 1px solid var(--lightgrey);
    padding: 0 1rem;
}

.right textarea{
    padding: 1rem 1rem;
}

.checkbox{
    display: flex;
    align-items: center;
}

.checkbox p{
    margin-left: 10px;
}

@media screen and (max-width: 960px) {
    .contact{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto;
        padding: 0 2rem;
    }

    .left{
        width: 100%;
        flex-direction: column;
    }

    .left>:nth-child(6){
        margin-bottom: 2rem;
        cursor: pointer;
    }

    .checkbox p{
        font-size: 0.6rem;
        margin-left: 10px;
    }

    .right button{
        margin-bottom: 6rem;
    }
}




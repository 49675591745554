.hero{
    background-color: var(--darkgrey);
    justify-content: space-between;
    padding: 2rem;
    max-width: 100%;
    height: 100vh;
    display: flex;
}

.left{
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    max-width: 40%;
}

.left>:nth-child(1){
    color: white;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 0.6rem;
}

.left>:nth-child(2){
    color: var(--primary);
    font-weight: bold;
    font-size: 3rem;
}

.left>:nth-child(3){
    margin: 2rem 0;
    color: var(--grey);
    font-size: 0.9rem;
}

.left>:nth-child(4){
    color: var(--lightgrey);
    font-size: 0.7rem;
}

.icons{
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    color: var(--lightgrey);
    font-size: 0.8rem;
}

.icons>:nth-child(1){
    margin-right: 1rem;
}

.icons>:nth-child(2){
    margin-right: 1rem;
}

.right{
    padding: 1rem;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: .8rem;
    margin-right: 20rem;
    margin-top: 5rem;
}

.right p{
    color: var(--grey);
}

.form-layout{
    width: 400px;
    margin: auto;
    background-color: #fff;
    border-radius: 6px;
}

.form-container{
    padding: 1.5rem 2rem;
}

.signin-text{
    margin-bottom: 20px;
}

.social-login i {
    background-color: #fff;
    padding: 16px 45px 6px 45px;
    border: 1px solid var(--lightgrey);
    border-radius: 4px;
}

.divider p{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--lightgrey);
    line-height: .1em;
    margin: 2rem 0 2rem;
}

.divider p span{
    background-color: #fff;
    padding: 0 10px;
}

form input{
    display: block;
    width: 100%;
    margin: 1.2rem 0;
    padding: 8px;
    border: 1px solid var(--lightgrey);
    border-radius: 4px;
}

.form-footer{
    border-top: 1px solid var(--lightgrey);
    background-color: #f8f8f8;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.form-footer p {
    padding: 1.5rem 2rem;
}

.form-footer span{
    font-weight: 500;
}

@media screen and (max-width: 960px) {
    .hero{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .left{
        padding: 0 1rem;
        max-width: 100%;
    }

    .left>:nth-child(1), .left>:nth-child(2){
        font-size: 2rem;
    }

    .right{
        margin: auto 1rem;
    }

}